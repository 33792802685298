<template>
	<el-container id="app">
		<el-header class="app-header" height="48px">
			<div class="ah-l">
				<i class="el-icon-menu" @click="handleMenu"></i>
			</div>
			<div class="ah-c">
				<span>Vue2 Case Demo</span>
				<span v-show="isHide">&nbsp;-&nbsp;{{ itemName }}</span>
			</div>
			<div class="ah-r">
				<i class="el-icon-back" @click="handleBack"></i>
			</div>
		</el-header>
		<div class="app-center">
			<div v-if="isHide"></div>
			<!-- 导航区 -->
			<el-aside width="160px" :hidden="isHide">
				<el-menu class="app-menu" router background-color="#F2F6FC" :default-active="$route.path">
					<el-menu-item v-for="route in routers" :key="route.path" :index="route.path">
						<i :class="route.meta.icon"></i>
						<span slot="title">{{ route.meta.title }}</span>
					</el-menu-item>
				</el-menu>
			</el-aside>
			<!-- 内容区 -->
			<el-main class="app-main">
				<router-view />
			</el-main>
		</div>
	</el-container>
</template>

<script>
import { routes } from "@/router";

export default {
	name: "App",
	data() {
		return {
			routers: routes,
			isHide: false,
			itemName: "数据面板"
		};
	},
	watch: {
		$route(to) {
			this.itemName = to.meta.title;
		}
	},
	methods: {
		handleMenu() {
			this.isHide = !this.isHide;
			localStorage.setItem("isHide", this.isHide ? 1 : 0);
		},
		handleBack() {
			if (this.$route.path !== "/") this.$router.push("/");
		}
	},
	mounted() {
		this.isHide = localStorage.getItem("isHide") === "1";
	}
};
</script>

<style lang="scss">
* {
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar {
	width: 4px;
	height: 5px;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-track:hover {
	border-radius: 5px;
}

html,
body {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#app {
	box-sizing: border-box;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: grid;
	grid-template-rows: 48px 1fr;

	.app-header {
		padding: 0 12px;
		background: #000;
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		user-select: none;

		.ah-c {
			font-size: 20px;
		}

		.ah-l,
		.ah-r {
			cursor: pointer;
			font-size: 24px;
			&:hover {
				color: #409eff;
			}
		}
	}

	.app-center {
		display: grid;
		grid-template-columns: auto 1fr;

		.app-menu,
		.app-main {
			height: calc(100vh - 48px);
			overflow: hidden;
			overflow-y: overlay;
		}
		.app-main {
			padding: 10px;
		}
	}
}
</style>
