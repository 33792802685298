import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const routes = [
	{
		path: "/",
		name: "OBR",
		meta: {
			title: "知识图谱",
			icon: "el-icon-magic-stick"
		},
		component: () => import("../views/obr/index.vue")
	}
];

const router = new VueRouter({
	mode: "history",
	base: "./",
	routes
});

export default router;
